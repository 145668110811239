import './card.scss';
import React, {useEffect} from 'react';
import {balanceText} from 'balanced-text';

export type CardProps = {
    title: string,
    subtitle?: string,
    children: React.ReactNode
}

export default function Card({title, subtitle, children}: CardProps) {
    useEffect(() => {
        balanceText({
            elements: '.has-balanced-text'
        })
    }, [title]);

    return (
        <div className="card">
            <h2 className="card__title">
                {title}
            </h2>
            {subtitle ?
                <p className="card__subtitle">{subtitle}</p>
                : null
            }
            <div className="card__body">
                {children}
            </div>
        </div>
    )
}