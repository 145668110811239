import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {EBirdMyDataSchema} from "../../../../ebird-mydata-reader";
import './big-year.scss';
import YouTube, {YouTubeProps} from "react-youtube";
import {EBirdTaxonomyEntry, useEBirdTaxonomy} from "../helpers/useEBirdTaxonomy";

export type BigYearProps = {
    observations: EBirdMyDataSchemaWithImage[],
    speciesImages: any,
    year: string | number | undefined,
    setObservationImages: (observations: EBirdMyDataSchema[]) => EBirdMyDataSchemaWithImage[]
}

export type EBirdMyDataSchemaWithImage = EBirdMyDataSchema & {
    image?: string | undefined;
    speciesGroup?: string | undefined;
}

export default function BigYear({observations, speciesImages, year, setObservationImages}: BigYearProps) {
    const rootEl = useRef() as React.MutableRefObject<HTMLDivElement>;
    const scrollEl = useRef() as React.MutableRefObject<HTMLDivElement>;
    const imagesEl = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [images, setImages] = useState<EBirdMyDataSchemaWithImage[]>();
    const zIndex = useRef(1);
    const [count, setCount] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const getNodeIndex = (elm: Element) => {
        const parentNode = elm?.parentNode?.children;
        if (parentNode) {
            // @ts-ignore
            return [...elm.parentNode.children].indexOf(elm);
        }

        return -1;
    }
    const [labels, setLabels] = useState<[string, any][]>([]);

    const VIDEO_ID = 'OlJeS_8jcc0';
    const VIDEO_LEN = 215;

    const scrollInterval = useRef<any>(null);
    const lastScrollTop = useRef(0);
    const scrollAmt = useRef(0);

    const showElement = (entries: any) => {
        let delay = 0;
        const DELAY_INTERVAL = 125;

        const newLabels = [];
        let max = 0;
        for (let entry of entries) {
            if (entry.isIntersecting) {
                if (entry.target.classList.contains('big-year__label')) {
                    console.log(entry.target);
                    newLabels.push(entry.target);
                }
                max = Math.max(max, getNodeIndex(entry.target));
            }
        }

        // Get any previously-missed entries
        // @ts-ignore
        let newEntries = [...document.querySelectorAll('.big-year__img')].slice(0, max).filter(el => !el.classList.contains('state-visible'));

        newEntries.sort((a, b) => {
            const iA = getNodeIndex(a.target);
            const iB = getNodeIndex(b.target);
            if (iA < iB) return -1;
            return 1;
        });

        newEntries = [...newLabels, ...newEntries];

        for (let entry of newEntries) {
            setTimeout(() => {
                const el = (entry as HTMLElement);
                el.classList.add('state-visible');
                el.style.zIndex = `${zIndex.current++}`;
            }, DELAY_INTERVAL);

            delay += DELAY_INTERVAL;
        }

        // @ts-ignore
        const visibleEls = [...document.querySelectorAll('.big-year__img')].filter(el => el.classList.contains('state-visible'));
        setCurrentIndex(visibleEls.length);
    }

    const scrollObserver = useRef<IntersectionObserver>(new IntersectionObserver(showElement, {
        // root: rootEl.current,
        // rootMargin: '-450px',
        rootMargin: `-${window.innerHeight / 2.3}px`,
        threshold: [0.01, 0.8]
    }));



    const getLabelPosition = (i: number) => {
        const el = document.querySelectorAll('.big-year__img').item(i) as HTMLElement;
        if (el) {
            return {
                top: (el.offsetTop - 100) + 'px',
                // top: (el.offsetTop) + 'px',
                display: 'block'
            }
        } else {
            return {
                display: 'none'
            }
        }
    }

    useEffect(() => {
        let tmpObs = setObservationImages(observations);
        tmpObs = tmpObs.sort((a, b) => {
            return a.taxonomicOrder < b.taxonomicOrder ? -1 : 1;
        });

        setImages(tmpObs);

        let labels: any = {}
        let i = 0;
        for (let obs of tmpObs) {
            if (obs.speciesGroup && !labels.hasOwnProperty(obs.speciesGroup)) {
                labels[obs.speciesGroup] = i;
            }
            /*console.log(obs.speciesGroup);
            console.log(i);*/
            i++;
        }

        const labelsArray = Object.entries(labels);
        labelsArray.sort((a: any, b: any) => {
            return a[1] < b[1] ? -1 : 1;
        })
        // console.log(labelsArray);
        setLabels(labelsArray);

        return function cleanup() {
            if (scrollInterval.current) {
                clearInterval(scrollInterval.current);
                scrollInterval.current = null;
            }

            if (scrollObserver.current) {
                scrollObserver.current.disconnect();
            }
        }
    }, []);

    const startShow: YouTubeProps['onReady'] = (e) => {
        e.target.playVideo();

        if (images?.length) {
            // console.log(images.length);
            // console.log(VIDEO_LEN / images.length);

            const imagesHeight = imagesEl.current.offsetHeight;
            const scrollDelay = 75;

            const heightPerImage = (imagesHeight / images.length) * 2.5;
            const cyclesPerSecond = (1000 / scrollDelay);

            // scrollAmt.current = 3.5;
            scrollAmt.current = heightPerImage / cyclesPerSecond;
            // scrollAmt.current = (heightPerImage / cyclesPerSecond) + 1.25;
            console.log(scrollAmt.current);
        }

        setTimeout(() => {
            const observedEls: NodeList = document.querySelectorAll('.big-year__img, .big-year__label');
            for (let el of observedEls as unknown as Array<Element>) {
                scrollObserver.current.observe(el as Element);
            }

            setIsLoading(false);

            const scrollInterval = setInterval(() => {
                if (scrollEl.current) {
                    scrollEl.current.scrollBy(0, scrollAmt.current);
                }

                if (scrollEl?.current?.scrollTop === lastScrollTop?.current) {
                    console.log('Done scrolling');
                    console.log(scrollInterval);
                    clearInterval(scrollInterval);
                } else {
                    lastScrollTop.current = scrollEl.current.scrollTop;
                    console.log('lastScrollTop', lastScrollTop.current);
                    console.log('currentScrollTop', scrollEl.current.scrollTop);
                }
            }, 75);
        }, 2000);
    }

    const getBigYearLabel = () => {
        if (year === 'life') {
            return 'Your Big Life List'
        }

        return `Your ${year} Big Year`;
    }

    return (
        <div className="big-year" ref={rootEl}>
            <>
                {isLoading ?
                    <div className="big-year__loading">
                        <div className="big-year__name">{getBigYearLabel()}</div>
                        <div className="big-year__load-anim">Loading&hellip;</div>
                    </div>
                    : null}
                <div className="big-year__scroll" ref={scrollEl}>
                    {images?.length ?
                        <div className="big-year__images" ref={imagesEl}>
                            {images.map((observation, i) => (
                                observation.image ?
                                    <div className="big-year__img" key={`img${i}`}>
                                        <img alt={observation.commonName} src={observation.image} />
                                        <div className="big-year__img-label">{observation.commonName}</div>
                                    </div>
                                    : null
                            ))}
                        </div>
                        : null
                    }
                    <div className="big-year__labels">
                        {images?.length && labels.map(label => (
                            <div className="big-year__label" style={getLabelPosition(label[1])}>{label[0]}</div>
                        ))}
                    </div>
                </div>
                <div className="big-year__count">
                    {currentIndex}
                </div>
                <YouTube
                    videoId={VIDEO_ID}
                    className="big-year__audio"
                    onReady={startShow}
                    />
            </>
        </div>
    )
}