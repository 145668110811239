import React, {useEffect, useState, createContext} from 'react';
import './App.scss';
import Header from './components/header';
import Footer from './components/footer';
import Welcome from "./views/welcome";
import UploadData from "./views/upload-data";
import SelectTimePeriod from "./views/select-time-period";
import MapView from "./views/map-view";
import WrapUp from "./views/wrap-up";
import {
    EBirdMyDataSchema,
    EBirdObservationsByLocation,
    EBirdObservationsBySpecies,
    getFilteredObservations,
    getMonthsWithObservations,
    getObservationsByLocation,
    getObservationsBySpecies
} from "ebird-mydata-reader";
import {generateMarkers} from "./helpers/helpers";
import {useGetSpeciesImages} from "./helpers/useGetSpeciesImages";
import {MapModeControl} from "./components/map-mode-control";
import LogRocket from 'logrocket';
import {EBirdTaxonomyEntry, useEBirdTaxonomy} from "./helpers/useEBirdTaxonomy";
import BigYear, {EBirdMyDataSchemaWithImage} from './views/big-year';

export const MapMode = {
    Year: 'year',
    Species: 'species',
    Location: 'location'
}
export type MapModeType = keyof typeof MapMode;

export type AppContextType = {
    lookupByTaxonomicOrder: (taxonomicOrder: number) => EBirdTaxonomyEntry|undefined
}

export const AppContext = createContext<AppContextType>({
    lookupByTaxonomicOrder: (taxonomicOrder => undefined)
});

function App() {
    const [dataRequested, setDataRequested] = useState(false);
    const [rawData, setRawData] = useState([]);
    const [years, setYears] = useState<number[]|string[]>([]);
    const [year, setYear] = useState<number|string>();
    const [months, setMonths] = useState<number[]|string[]>([]);
    const [month, setMonth] = useState<number|string>();
    const [lifeList, setLifeList] = useState<EBirdMyDataSchema[]>([]);
    const [filteredObservations, setFilteredObservations] = useState<EBirdMyDataSchema[]>([]);
    const [observationsBySpecies, setObservationsBySpecies] = useState<EBirdObservationsBySpecies[]>([]);
    const [observationsByLocation, setObservationsByLocation] = useState<EBirdObservationsByLocation[]>([]);
    const [markers, setMarkers] = useState<any>();
    const [showWrapUp, setShowWrapUp] = useState(false);
    const [showBigYear, setShowBigYear] = useState(false);
    const [mapMode, setMapMode] = useState(MapMode.Year as MapModeType);
    const [activeLocation, setActiveLocation] = useState<any>();
    const [activeSpecies, setActiveSpecies] = useState<any>();

    const { speciesImages, getSpeciesImages } = useGetSpeciesImages();
    const { lookupByTaxonomicOrder } = useEBirdTaxonomy();

    const openMyDataRequest = () => {
        window.open('https://ebird.org/downloadMyData');
        localStorage.setItem('ebird-data-requested', 'true');
        setDataRequested(true);
    }

    const resetMyDataRequest = () => {
        localStorage.removeItem('ebird-data-requested');
        setDataRequested(false);
    }

    const onStartBigYear = () => {
        setShowBigYear(true);
    }

    useEffect(() => {
        const dataRequested = localStorage.getItem('ebird-data-requested');
        if (dataRequested) {
            setDataRequested(true);
        }

        LogRocket.init('z3y7i6/birdmap-explorer');
    }, []);

    useEffect(() => {
        if (!year) return;

        const parsedYear = year === 'life' ? year : parseInt(year as string);

        const months = getMonthsWithObservations(rawData, parsedYear);
        setMonths(months);
    }, [year]);

    const setTimePeriod = (year: string, month: string) => {
        // console.log(`Setting time period: ${year}, ${month}`);
        const parsedYear = year === 'life' ? year : parseInt(year);
        const parsedMonth = parseInt(month) ?? null;
        setFilteredObservations(getFilteredObservations(rawData, parsedYear, parsedMonth));
        console.log(filteredObservations);
    }

    const setSpecies = (taxonomicOrder: number) => {
        const foundSpecies = observationsBySpecies.find(el => el.taxonomicOrder === taxonomicOrder);

        if (foundSpecies) {
            setFilteredObservations(foundSpecies.observations);
            setActiveSpecies(taxonomicOrder);
        }
    }

    const startOver = () => {
        setRawData([]);
        setFilteredObservations([]);
        setLifeList([]);
        setYears([]);
        setYear(undefined);
        setMonth(undefined);
        setMarkers(undefined);
        setShowWrapUp(false);
        setShowBigYear(false);
    }

    useEffect(() => {
        const bySpecies: EBirdObservationsBySpecies[] = getObservationsBySpecies(rawData);
        const byLocation: EBirdObservationsByLocation[] = getObservationsByLocation(rawData);
        // console.log(bySpecies);
        // console.log(byLocation);

        if (rawData?.length) {
            const lifeList: EBirdMyDataSchema[] = getFilteredObservations(rawData, 'life');
            setLifeList(lifeList);
        }

        setObservationsBySpecies(bySpecies);
        setObservationsByLocation(byLocation);
    }, [rawData]);

    useEffect(() => {
        // console.log('Generating markers');
        const markers = generateMarkers(filteredObservations);

        getSpeciesImages(markers);
        setObservationImagesForMarkers(markers);
        setObservationImages(filteredObservations);
        setMarkers(markers);

    }, [filteredObservations]);

    useEffect(() => {
        if (markers?.length) {
            setObservationImagesForMarkers(markers);
        }

    }, [speciesImages]);

    const setObservationImages = (observations: EBirdMyDataSchemaWithImage[]): EBirdMyDataSchemaWithImage[] => {
        for (let observation of observations) {
            if (Object.prototype.hasOwnProperty.call(speciesImages, observation.taxonomicOrder)) {
                observation.image = speciesImages[observation.taxonomicOrder].medium;
            }

            const taxonomicData = lookupByTaxonomicOrder(observation.taxonomicOrder);
            if (taxonomicData) {
                observation.speciesGroup = taxonomicData.speciesGroup;
            }
        }

        return observations;
    }

    const setObservationImagesForMarkers = (markers: any) => {
        for (let marker of markers.features) {

            /*let observationPhotoId;
            if (marker.properties.mlCatalogNumbers) {
                const catalogIds = marker.properties.mlCatalogNumbers.toString().split(' ');
                if (catalogIds?.length) {
                    observationPhotoId = catalogIds[0];
                }
            }*/

            /*if (observationPhotoId) {
                marker.properties.image = `https://cdn.download.ams.birds.cornell.edu/api/v1/asset/${observationPhotoId}/320`;
            } else*/

            if (Object.prototype.hasOwnProperty.call(speciesImages, marker.properties.taxonomicOrder)) {
                marker.properties.image = speciesImages[marker.properties.taxonomicOrder].thumb;
            }
        }
    }

    const onShowWrapUp = () => {
        console.log('Wrap up!');
        setShowWrapUp(true);
    }

    return (
        <div className="app-wrapper">
            <AppContext.Provider value={{lookupByTaxonomicOrder}}>
                <Header
                    showStartOver={(filteredObservations?.length && markers?.features?.length) || showWrapUp}
                    onStartOver={startOver}
                />
                <main className="app-main">
                    {!dataRequested && <Welcome onRequestData={openMyDataRequest} />}
                    {dataRequested && !rawData?.length ?
                        <UploadData
                            onSetData={setRawData}
                            onSetYears={setYears}
                            onResetDataRequest={resetMyDataRequest}
                        /> : null
                    }
                    {dataRequested && rawData?.length && !(month && year) && !markers?.features.length && !showWrapUp ?
                        <SelectTimePeriod
                            lifeList={lifeList}
                            years={years}
                            months={months}
                            onSetMonth={setMonth}
                            onSetYear={setYear}
                            onSetTimePeriod={setTimePeriod}
                            onShowWrapUp={onShowWrapUp}
                        />
                        : null
                    }
                    {filteredObservations?.length && markers?.features?.length && !showBigYear && !showWrapUp ?
                        <>
                            <MapView
                                markers={markers}
                                year={year}
                                month={month}
                                mode={mapMode}
                                showPlayControls={mapMode === MapMode.Year}
                                activeSpecies={activeSpecies}
                                activeLocation={activeLocation}
                                setActiveLocation={setActiveLocation}
                                observationsByLocation={observationsByLocation}
                                setObservationImages={setObservationImages}
                            />
                            <MapModeControl
                                mode={mapMode}
                                setMode={setMapMode}
                                years={years}
                                months={months}
                                appMonth={month}
                                appYear={year}
                                onSetMonth={setMonth}
                                onSetYear={setYear}
                                onSetTimePeriod={setTimePeriod}
                                observationsBySpecies={observationsBySpecies}
                                onSetSpecies={setSpecies}
                                onStartBigYear={onStartBigYear}
                                onShowWrapUp={onShowWrapUp}
                            />
                        </>
                        : null
                    }
                    {showWrapUp ?
                        <WrapUp
                            observations={filteredObservations}
                            year={year}
                            month={month}
                        />
                        : null
                    }
                    {showBigYear ?
                        <BigYear
                            observations={filteredObservations}
                            speciesImages={speciesImages}
                            setObservationImages={setObservationImages}
                            year={year}
                        />
                        : null
                    }
                </main>
                <Footer />
            </AppContext.Provider>
        </div>
    );
}

export default App;
